import { render, staticRenderFns } from "./collapsed-block.vue?vue&type=template&id=8e25b1be&scoped=true&"
import script from "./collapsed-block.ts?vue&type=script&lang=ts&"
export * from "./collapsed-block.ts?vue&type=script&lang=ts&"
import style0 from "./collapsed-block.vue?vue&type=style&index=0&id=8e25b1be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e25b1be",
  null
  
)

export default component.exports